import React from "react";
import Investment from "../components/invest/Investment";
import Layout from "../components/Layout/Layout";

const Invest = () => {
  return (
    <Layout>
      <Investment />
    </Layout>
  );
};

export default Invest;
