import React from "react";
import Referral from "../components/referrals/Referral";
import Layout from "../components/Layout/Layout";

const Refer = () => {
  return (
    <Layout>
      <Referral />
    </Layout>
  );
};

export default Refer;
